@tailwind base;
@tailwind components;
@tailwind utilities;



:root {
  --primary-color: #6a2588;
  --primary-bg-color: #eef3fa;
  --secondary-bg-color: white;
  --test-color: aquamarine;
}
/* fonts.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;1,100;1,200&display=swap');
body{
  font-family: "Montserrat";
}




