.custom-menu {
  display: none;
}

.custom-menu.active {
  display: flex;
}

.main-container {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.middle-container {
  max-width: 70rem;
  margin: auto;
}

.main-heading {
  font-size: clamp(1.5rem, 2.5vw + 1rem, 3.75rem);
  line-height: normal;
  font-weight: bold;
}

.sub-heading {
  font-size: 3rem;
  line-height: normal;
  font-weight: bold;
}

.welcome-page {
  padding-top: clamp(3rem, 14vh, 14rem);
  min-height: 768px;
  position: relative;
}

.fexo-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/Picture1.png");
  background-size: 48%;
  background-repeat: no-repeat;
  background-position: bottom clamp(3rem, 17vh, 18.5rem) right;
  transition: transform 0.3s ease;
  z-index: 1;
}

.website-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/website_img.png");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: bottom clamp(3rem, 17vh, 18.5rem) right;
  transition: transform 0.3s ease;
  z-index: 1;
}


@media (max-height: 768px) {
  .fexo-bg-image,
  .website-bg-image {
    background-position: bottom clamp(0.5rem, 5vh, 2rem) right;
  }
  .website-bg-image {
    background-position: bottom clamp(0.5rem, 5vh, 2rem) right;
  }
}

@media (max-width: 1024px) {
  .welcome-page {
    padding-top: clamp(3rem, 11vh, 14rem);
  }
  .fexo-bg-image,
  .website-bg-image {
    background-position: bottom clamp(2rem, 8vh, 6rem) right;
  }
  .website-bg-image {
    background-position: bottom clamp(2rem, 8vh, 6rem) right;
  }
}

@media (max-width: 768px) {
  .main-heading {
    font-size: clamp(2rem, 8vw, 2.8rem); /* min:2rem  max:2.5  */
    line-height: normal;
  }
  .sub-heading {
    font-size: clamp(1.8rem, 7vw, 2.5rem);
    line-height: normal;
  }
  .fexo-bg-image,
  .website-bg-image {
    background-position: bottom clamp(12rem, 30vh, 16.5rem) right;
    background-size: clamp(40%, 50vw, 60%);
  }
}

@media (max-width: 640px) {
  .fexo-bg-image,
  .website-bg-image {
    background-position: bottom clamp(12rem, 35vh, 18.5rem) right;
    background-size: clamp(40%, 50vw, 60%);
  }
}

@media (max-width: 400px) {
  .welcome-page {
    padding-top: 2rem;
  }
  .fexo-bg-image,
  .website-bg-image {
    background-position: bottom clamp(8rem, 28vh, 18.5rem) right;
    background-size: clamp(40%, 50vw, 60%);
  }
}
