.custom-container-6{
    padding: 0 4% 0 5%;
    position: relative;
    background-color: var(--secondary-bg-color);
}

.custom-icon-container{
    font-size: 25px;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}

.signup-desc{
    margin: 0 0 5%;
    font-size: 20px;
}


.email-button{
    text-decoration: none;
    color:white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.email-button:hover{
    color:var(--primary-color);
    cursor: pointer;
}

.socials {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.socials:hover {
    color: var(--primary-color); /* Hover color */
    cursor: pointer;
}

.poplink {
    color: white;
    text-decoration: underline;
    transition: color 0.3s;
}

.poplink:hover {
    color: var(--primary-color); /* Hover color */
    cursor: pointer;
}

.input-container input {
    width: 70%;
    border: none;
    border-bottom: 1px solid #000; 
    padding: 16px 0;
    outline: none;
    background-color: transparent; 
    transition: border-bottom-color 0.3s ease;
}

.input-container label {
    pointer-events: none;
    transition: all 0.3s ease; /* Add a smooth transition for label movement */
}

.input-container input:focus {
    border-bottom-color: #000;
}

.sub-button{
    background-color: black;
    color:var(--secondary-bg-color);
    font-weight: bold;
    width: 140px;
    height: 60px;
    transition: background-color 0.3s;
    border-radius: 30px;
}

.sub-button:hover{
    background-color: var(--primary-color);
}

.footer-addresses{
    font-size: 18px;
}

.close-modal{
    background-color: black;
    color:var(--secondary-bg-color);
    border-radius: 10px;
    text-decoration: none;
    transition: background-color 0.5s;
}

.close-modal:hover{
    background-color: var(--primary-color);
    color:var(--secondary-bg-color);
}

.success-modal{
    border:1px;
}

.error-modal{
    border: 1px;
}


