.image-container-inovice {
  aspect-ratio: 1.9;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  box-shadow: 0 0 40px 8px rgba(0, 0, 0, 0.2);
}

ul {
  list-style-type: disc;
  margin-left: 5rem;
}

table {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  width: 50%;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
ol {
  list-style-type: decimal;
}

.image-container-inovice iframe {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 15px 15px 15px 15px;
}

.bottom-image {
  padding: 5rem 0;
  background-image: url("../assets/bottom_image.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.bottom-image-2 {
  padding: 5rem 0;
  background-image: url("../assets/bottom_image_2.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.static-subheading {
  font-size: 1.5rem;
  line-height: normal;
  color: #2e0040;
  font-weight: 600;
  margin: 1.5rem 0 1rem 0;
}

@media (max-width: 1400px) {
  .image-container-inovice {
    aspect-ratio: 1.6;
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    box-shadow: 0 0 40px 8px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 1200px) {
  .bottom-image {
    width: 100%;
    padding: 5rem 0;
    background-image: none;
    text-align: center;
  }
  .bottom-image-2 {
    width: 100%;
    padding: 5rem 0;
    background-image: none;
    text-align: center;
  }
  .image-container-inovice {
    aspect-ratio: 1.9;
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    box-shadow: 0 0 40px 8px rgba(0, 0, 0, 0.2);
  }
}

.scrolling-wrapper {
  display: flex;
  width: max-content; 
  animation: scroll 20s linear infinite; 
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

