.form-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2.5rem;
  column-gap: 3rem;
  font-weight: 700;
}

.field-content{
  padding: 3px 3px;
  width: fit-content;
  font-weight: normal;
}

.field-skeleton{
  width: 160px;
  height: 50px;
}