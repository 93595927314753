.button-highlight {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
}

.button-highlight:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(140deg, rgb(230, 192, 68), orange, violet, rgb(187, 57, 187), yellow);
  background-size: 400%;
  filter: blur(20px);
  border-radius: inherit;
  z-index: -1;
}

.button-highlight:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  z-index: -1;
}

.button-highlight::before {
  filter: none;
}

.button-highlight::after {
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
