textarea {
  outline: none !important;
  outline-style: none;
  padding: 8px;
  /* border: 0.5px solid rgb(233, 231, 231) !important; */
  box-shadow: none;
}

textarea:focus {
  outline: none !important;
  outline-style: none;
  /* border: 2px solid #2E0040 !important; */
  box-shadow: none;
}

.typing-animation {
  display: inline-block;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #cf9134;
  border-radius: 50%;
  margin-right: 4px;
  animation: dot-blink 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  animation-delay: 0.9s;
}

.dot:nth-child(5) {
  animation-delay: 1.2s;
}

@keyframes dot-blink {
  0%, 20%, 100% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-8px);
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(192, 192, 192);
}
.scroller {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-color: rgb(192, 192, 192);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}

/* *::-webkit-scrollbar-track { */
  /* box-shadow: inset 0 0 2px rgba(112, 112, 112, 0.3); */
/* } */

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}